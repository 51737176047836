import { useSearchStateStore } from "@/Stores/SearchState"
import { useNotificationsStore } from '@/Stores/Notifications'

export function GetSearch(to) {
    return axios.get(`/searches/${ to.params.index }`)
        .then(response => {
            useSearchStateStore().updateSearch(response.data)
            to.params.search = response.data
            return true
        })
        .catch(error => {

            // Display errors thrown by the backend when trying to access a search
            if (error.data?.errors?.metadata) {

                const notifications = useNotificationsStore()
                let errorMessage = error.data.errors.metadata[0].message

                notifications.error({
                    title: 'Failed to create search',
                    message: error?.data?.exception ?? errorMessage,
                    timeout: 20000,
                })

                return false
            }

            return { name: 'NotFound' }
        })
}
