import { useAuthStore } from '@/Stores/Auth'

export function useLanguageOptions() {

    const auth = useAuthStore()

    // defaults set for shared searches, folders etc.
    const language_preference = auth.user.preferences?.language_preference ?? 'en'
    const auto_translate = auth.user.preferences?.auto_translate ?? true

    /**
     * Work out what language the title / abstract / first claims should be in based on language_preference and auto_translate user preferences and what languages are available for this document.
     *
     * @param {object} doc
     * @param {string} field
     * @param {boolean} highlighting
     * @returns {string}
     */
    function getTextInPreferredLanguage(doc, field, highlighting = false) {

        let text = null
        let data = null

        if (language_preference !== 'en' || auto_translate === false) {

            let language = ! auto_translate
                ? doc.lang
                : language_preference

            switch (field) {
                case 'title' :
                    data = getFieldTexts(doc, 'titles', highlighting)
                    data?.some(title => {
                        if (title.includes('lang="' + language + '"')) {
                            text = title
                            return true
                        }
                    })
                    if (! text) text = doc?.firstEnglishTitle
                    break

                case 'abstract' :
                    data = getFieldTexts(doc, 'abstracts', highlighting)
                    data?.some(abstract => {
                        if (abstract.includes('lang="' + language + '"')) {
                            text = abstract
                            return true
                        }
                    })
                    if (! text) text = doc?.firstEnglishAbstractNoImages
                    break

                case 'first_claim' :
                    data = getFieldTexts(doc, 'firstclaims', highlighting)
                    data?.some(firstclaims => {
                        if (firstclaims.includes('lang="' + language + '"')) {
                            text = firstclaims
                            return true
                        }
                    })
                    if (! text) text = doc?.firstEnglishFirstClaims
                    break
            }
        }
        else {
            switch (field) {
                case 'title' :
                    text = doc.firstEnglishTitle
                    break
                case 'abstract' :
                    text = doc.firstEnglishAbstractNoImages
                    break
                case 'first_claim' :
                    text = doc.firstEnglishFirstClaims
                    break
            }
        }

        return text

    }

    function getFieldTexts(doc, fieldName, highlighting) {
        let data;
        if (highlighting) {
            //entireFieldHl is populated when hl contains KWIC rather than the entire text
            data = doc?.highlighting?.entireFieldHl?.[fieldName] ?? doc?.highlighting?.hl?.[fieldName]
        } else {
            data = doc?.[fieldName]
        }
        return data
    }

    return {
        getTextInPreferredLanguage
    }
}
