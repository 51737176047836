import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useOriginPlusStore = defineStore('origin_plus', () => {

    /**
     * Array of alerts
     */
    const alerts = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Array of tasks the user can see
     */
    const tasks = ref(null)

    /**
     * The task the user is currently viewing
     */
    const task = ref(null)

    /**
     * Array of subscriptions the user has access to
     */
    const subscriptions = ref(null)

    /**
     * Array of all tasks on the organization
     */
    const taskReport = ref(null)

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(alerts.value == null);
    });

    /**
     * Get all alerts
     */
    const getAlerts = computed(() => {
        if (alerts.value !== null) {
            return alerts.value
        }
        return []
    })

    const getOriginPlusAlerts = computed(() => {
        if (alerts.value !== null) {
            return alerts.value
        }
        return []
    })

    const getTasks = computed(() => {
        if (tasks.value !== null) {
            return tasks.value
        }
        return []
    })

    const getTask = computed(() => {
        if (task.value !== null) {
            return task.value
        }
        return []
    })

    const getTaskUsers = computed(() => {
        return getTask.value?.alert_run?.alert?.inspectors_users ?? []
    })

    const getTaskGroups = computed(() => {
        return getTask.value?.alert_run?.alert?.inspectors_groups ?? []
    })

    const getSubscriptions = computed(() => {
        if (subscriptions.value !== null) {
            return subscriptions.value
        }
        return []
    })

    const getReport = computed(() => {
        if (taskReport.value !== null) {
            return taskReport.value
        }
        return []
    })

    function fetchSubscriptions() {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/origin-plus/subscriptions').then(response => {
                subscriptions.value = response.data
                updating.value = false;
                resolve();
            }, err => {
                updating.value = false;
                reject();

            });

            if (hasData.value) {
                resolve();
            }
        })
    }

    function subscribeTo(subscription) {
        return new Promise((resolve, reject) => {
            axios.post(`/origin-plus/subscribe/${subscription.id}`)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error)
            })
        })
    }

    function unsubscribeFrom(subscription) {
        return new Promise((resolve, reject) => {
            axios.delete(`/origin-plus/subscribe/${subscription.id}`)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                reject(error)
            })
        })
    }

    function fetchAlerts() {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/origin-plus/alerts').then(response => {
                alerts.value = response.data;
                updating.value = false;
                resolve();
            }, err => {
                updating.value = false;
                reject();
            });

            if (hasData.value){
                resolve();
            }
        });
    }

    function fetchTasks() {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/origin-plus/tasks').then(response => {
                tasks.value = response.data
                updating.value = false;
                resolve();
            }, err => {
                updating.value = false;
                reject();

            });

            if (hasData.value){
                resolve();
            }
        });
    }

    function fetchTask(uuid) {
        return new Promise((resolve, reject) => {
            axios.get(`/origin-plus/tasks/${ uuid }`)
                .then(response => {
                    task.value = response.data
                    updating.value = false;

                    resolve()
                })
                .catch(error => {
                    reject(error)
                })

        });
    }

    /**
     * Get an alert by ID
     */
    function getAlert(alertId) {
        if (alerts.value !== null) {
            return alerts.value.find(alert => alert.id === Number(alertId))
        }

        return null
    }

    function fetchReport(dateFrom = null, dateTo = null) {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/origin-plus/report', {
                params: {
                    date_from: dateFrom,
                    date_to: dateTo
                }
            }).then(response => {
                taskReport.value = response.data
                updating.value = false;
                resolve();
            }, err => {
                updating.value = false;
                reject();

            });

        });
    }

    function fetchUserReport(userId, type, dateFrom, dateTo) {

        return new Promise((resolve, reject) => {
            axios.get(`/origin-plus/report/expanded/${userId}`, {
                params: {
                    type,
                    date_from: dateFrom,
                    date_to: dateTo
                }
            }).then(response => {
                resolve(response.data);
            }, err => {
                updating.value = false;
                reject(err);

            });

        });
    }

    return {
        fetchAlerts,
        getAlert,
        getAlerts,
        getOriginPlusAlerts,
        alerts,
        hasData,
        updating,
        fetchTasks,
        fetchTask,
        getTasks,
        getTask,
        getTaskUsers,
        getTaskGroups,
        fetchSubscriptions,
        getSubscriptions,
        subscribeTo,
        unsubscribeFrom,
        fetchReport,
        getReport,
        fetchUserReport
    }
})
