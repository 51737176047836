import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useAuthStore } from '@/Stores/Auth'

export const useNotificationsStore = defineStore('notifications', () => {
    const authStore = useAuthStore()

    // the array of notification objects
    const stack = ref([])

    // push a notification to the stack
    function notify(options) {
        let notification = createNotification(options)
        stack.value.push(notification)

        if (notification.autodismiss) {
            setTimeout(() => {
                dismiss(notification.id)
            }, notification.timeout)
        }
    }

    function notifyType(type = 'info', options = {}) {
        let params = createNotification({ ...options, ...{ type: type }})

        return notify(params)
    }

    function success(options) {
        notifyType('success', options)
    }

    function info(options) {
        notifyType('info', options)
    }

    function warning(options) {
        notifyType('warning', options)
    }

    function error(options) {
        notifyType('error', options)
    }

    // create a notification object
    function createNotification(options) {
        return {
            id: Date.now()+stack.value.length,
            title: options.title ?? '',
            message: options.message ?? '',
            type: options.type ?? 'info',
            show: true,
            autodismiss: options.autodismiss ?? true,
            timeout: options.timeout ?? 5000,
            undoData: options.undoData ?? null,
            position: authStore.user.preferences?.system_message ?? 'topright'
        }
    }

    // dismiss a notification
    function dismiss(id) {
        let getIndex = () => stack.value.findIndex(notification => notification.id == id)
        let index = getIndex()

        if (index > -1 && stack.value[index].show) {
            stack.value[index].show = false
            setTimeout(() => stack.value.splice(getIndex(), 1), 300)
        }
    }

    /**
     * Handles the undo action for a given notification.
     *
     * Performs the undo operation using the provided HTTP method and endpoint.
     * If the undo action is successful and a loggedEventId is provided, it
     * marks the corresponding logged event as 'undone'.
     *
     */
    function undo(notification) {

        if (!notification.endpoint) return

        const { method, endpoint, loggedEventId } = notification

        axios({
            method,
            url: endpoint,
        })
            .then(async (response) => {
                notify({
                    title: 'Undo Successful',
                    message: response.data.message,
                    type: 'success',
                })

                // Mark the logged event as 'Undone' if the loggedEventId exists
                if (loggedEventId) {
                    try {
                        await axios.delete(`/logged_events/${loggedEventId}`)
                    } catch (error) {
                        console.error(`Failed to update the logged event:`, error)
                    }
                }
            })
            .catch((error) => {
                notify({
                    title: 'Undo Failed',
                    message: 'Unable to undo the action.',
                    type: 'error',
                })
            })
    }


    return { stack, notify, dismiss, success, info, warning, error, undo }
})
