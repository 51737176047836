import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useListedPatentsStore = defineStore('listed_patents', () => {

    /**
     * The user's patent list
     */
    const listedPatents = ref([])

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(listedPatents.value == null)
    });

    /**
     * Fetch the user's patent list
     */
    function fetchPatentList() {

        updating.value = true;

        return new Promise((resolve, reject) => {
            axios.get('/listed_patents').then(response => {
                listedPatents.value = response.data
                updating.value = false
                resolve()
            }, err => {
                updating.value = false
                reject()
            });

            if (hasData.value){
                resolve()
            }
        });
    }

    /**
     * Retrieve a single item for the user's patent list. Useful for when viewing a patent; we don't need to
     * refresh the entire list, just check if one item exists.
     */
    function fetchOne(ucid) {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get(`/listed_patents/${ ucid }`)
                .then(response => {
                    if (! response.data) {
                        if (Array.isArray(listedPatents.value)) {
                            listedPatents.value = listedPatents.value.filter(patent => patent.ucid !== ucid)
                        }
                        resolve()
                    }
                    mergeListedPatent(response.data)
                    resolve()
                })
                .catch(error => reject())
                .finally(() => updating.value = false)
        })
    }

    /**
     * Merge a listed patent retrieved from the backend to the frontend local state
     */
    function mergeListedPatent(listedPatent) {

        if (! isInList(listedPatent)) {
            if (! Array.isArray(listedPatents.value)) {
                listedPatents.value = []
            }
            listedPatents.value.push(listedPatent)
        }
    }

    /**
     * Merge multiple listed patents retrieved from the backend to the frontend local state
     */
    function mergeListedPatents(items) {
        items.forEach(listedPatent => mergeListedPatent(listedPatent))
    }

    /**
     * Determine if a given ucid or array of ucids is in the user's patent list
     */
    function isInList (ucids) {
        if (! hasData.value || ! Array.isArray(listedPatents.value) || (Array.isArray(ucids) && ! ucids.length)) {
            return false
        }

        if (Array.isArray(ucids)) {
            let listedUcids =  listedPatents.value.map(ucid => ucid.ucid)
            return ucids.every(ucid => listedUcids.includes(ucid))
        }
        else return !!listedPatents.value.find(patent => patent.ucid === ucids)
    }

    /**
     * Add a single patent to the user's list
     */
    function add(ucid) {
        return axios.post('/listed_patents', { ucid })
    }

    /**
     * Remove a single patent from the user's list
     */
    function remove(ucid) {
        return axios.delete(`/listed_patents/${ ucid }`)
    }

    /**
     * Add many patents to the user's list
     */
    function addMany(ucids) {
        return axios.post(`/listed_patents/bulk_add`, { ucids })
            .then(() => fetchPatentList())
    }

    /**
     * Remove many patents from the user's list
     */
    function removeMany(ucids) {
        return axios.post('/listed_patents/bulk_delete', { ucids })
            .then(() => fetchPatentList())
    }

    /**
     * Clear the user's patent list
     */
    function clearAll() {
        return axios.delete('/listed_patents')
            .then(() => fetchPatentList())
    }

    return {
        fetchPatentList,
        fetchOne,
        isInList,
        add,
        remove,
        addMany,
        removeMany,
        clearAll,
        listedPatents,
        updating,
        hasData
    }
})
