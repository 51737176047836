import {useDoc, useFormatPublicationNumber} from '@/Composables/PatentDocument'
import {useCreatePatentSearch, usePatentSearch} from '@/Composables/PatentSearch'

export function GetPatentDocument(to) {

    const { request } = usePatentSearch()
    request.updateRows(1)
    request.updateStart(0)
    request.updateGrouping('none')

    return axios.post(`patents/search/browse`, {
            ...request.filteredPayload,
            q: `pn="${to.params.ucid}"`,
        })
        .then(response => {
        if (response.data.successful && response.data.docs.length > 0) {
            to.params.doc = useDoc(response.data.docs[0])

            //create a new search history record when accessing this link
            useCreatePatentSearch({query: `pn=(${to.params.doc.publicationnumber})`, type: 'commandline'}, {
                onSuccess: search => {
                    return true
                }, onError: error => {
                    return { name: 'NotFound' }
                }
            })

            return true
        }
        return { name: 'NotFound' }
    })
        .catch(error => {
            return { name: 'NotFound' }
        })
}

